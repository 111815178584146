import React from "react"

import Layout from "../../components/Layout"

import { Link } from "gatsby"

import mea1 from "../../images/monitoraea/monitoraea-content1.png";
import mea2 from "../../images/monitoraea/monitoraea-content2.png";
import mea3 from "../../images/monitoraea/monitoraea-content3.png";
import mea4 from "../../images/monitoraea/monitorea-content4_r2.jpeg";


export default function Home() {
  return (
    <Layout breadcrumbs={["MonitoraEA"]}>
      <div id="heading2" >
        <h1>MonitoraEA</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5 className="monitorea"><Link to="/">Inicio</Link> &gt;&gt; Projetos  &gt;&gt; MonitoraEA</h5>}

            <header>
              <h2 className="monitorea">Cuidado público com o meio ambiente</h2>
            </header>

            <div style={{ display: 'flex' }}>
              <div>
                <p className="headline">O governo somos todos nós! E políticas públicas de educação ambiental podem ser promovidas por todos que amam o meio-ambiente.</p>
                <p><span className="image right desktop-only"><img src={mea1} /></span>O MonitoraEA é o Sistema Brasileiro de Monitoramento e Avaliação de Políticas Públicas de Educação Ambiental. Um de seus pilares é a plataforma colaborativa de mapeamento, monitoramento e avaliação de Políticas Públicas de Educação Ambiental (PPEA), abrangendo todo o território brasileiro.</p>
                <span className="image-page mobile-only"><img src={mea1} /></span>                
                <p>Partindo da premissa de que a política pública deve ser compreendida de maneira coletiva e plural, não circunscrita necessariamente à esfera estatal, o MonitoraEA se coloca como um sistema no qual a diversidade de atores que se relacionam com os temas das PPEA no Brasil atuam juntos. Auxiliados pela plataforma, eles se reconhecem e se conectam, monitoram e avaliam suas práticas, refletem e colaboram, porém, acima de tudo, aprendem uns com os outros.<span className="image right desktop-only"><img src={mea4} /></span></p>
                <span className="image-page mobile-only"><img src={mea4} /></span>                                
                <p> A Articulação Nacional de Políticas Públicas de Educação Ambiental (ANPPEA) é o espaço de construção e governança do MonitoraEA. O Engajados atua no desenvolvimento das ferramentas tecnológicas. A plataforma, organizada em torno da colaboração, ainda está em construção, mas já pode ser acessada por quem se interessar. </p>                                
                <p>A nova etapa de desenvolvimento do MonitoraEA é dedicada a uma parte do seu ecossistema, o MonitoraEA PPP-ZCM (Projeto Político Pedagógico da Zona Costeira e Marinha do Brasil), financiado pela Cooperação Alemã para o Desenvolvimento Sustentável, a GIZ. Durante os próximos dois anos, os projetos do eixo operacional do PPP, em toda a costa brasileira, serão articulados e monitorados com auxílio das tecnologias que estamos desenvolvendo.<span className="image right desktop-only"><img src={mea3} /></span> </p>
                <span className="image-page mobile-only"><img src={mea3} /></span>                                
                <ul>
                  <li>Acesse o projeto (versão beta) em: <a href="http://monitoraea.org.br/" target="_blank">www.monitoraea.org.br</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
